import "./App.css";
//import logo svg
import foto from "./assets/images/smoko.svg";
import gif from "./assets/images/video.gif";

function App() {
  return (
    <div class="container">
      <img className="videoTag" src={gif} alt="Centered Image" />

      {/* <video className="videoTag" autoPlay loop muted>
        <source
          src={
            "https://firebasestorage.googleapis.com/v0/b/toylibrarynz.appspot.com/o/video.mp4?alt=media&token=4f081987-56bf-4fc2-a6c3-206c5a92448e"
          }
          type="video/mp4"
        />
      </video> */}
      <div class="overlay"></div>
      <a href="https://www.eventfinda.co.nz/2024/migrantes-relatos-al-sur-del-sur/auckland/newton">
        <img src={foto} class="centered-image" alt="Centered Image" />
      </a>
    </div>
  );
}

export default App;
